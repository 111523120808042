import { useCallback, useEffect, useRef, useState } from "react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";
import toLower from "lodash/toLower";
import uniqueId from "lodash/uniqueId";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import {
  Button,
  ButtonLink,
  ButtonPrimary,
  ButtonText,
  Checkbox,
  DropDown,
  DropDownItem,
  ErrorMessage,
  InfoBox,
  InputText,
  RadioGroup,
  RadioGroupItem,
  Stack,
  TabbedModalHeader,
  TabbedModalHeaderItem,
  ToggleButton,
  Tooltip,
} from "@/design-system";
import { InlineIconImg } from "@/design-system/components/InlineIcon";
import { urlKnowledgeBaseHaventReceivedTrialSessions } from "@/helpers-ts";
import { useModal2 } from "@/hooks/useModal2";
import { selectIsUserOwnerOrAdmin } from "@/selectors/user";
import ButtonGroup from "../../components/ButtonGroup";
import { FeatureGateMessage } from "../../components/FeatureGateMessage";
import { Modal2 } from "../../components/Modal";
import ModalHeader from "../../components/ModalHeader";
import { ScreenerModalContent } from "../../components/ScreenerModalContent";
import Spinner from "../../components/Spinner";
import { TesterQuantitySelection } from "../../components/TesterQuantitySelection";
import {
  convertScreenerToApiFormat,
  copyTextToClipboard,
  formatDateWithoutTime,
  formatNumber,
  formatPrice as formatPriceWithCurrency,
  getFromYourPlanWording,
  participateUrl,
  usePrevious,
} from "../../helpers";
import {
  getPeriodNameByIdendtifier,
  getWordingForTargetingValue,
} from "../../helpers";
import { useFreeTrial, useIsFreeTrial } from "../../hooks/freeTrialHooks";
import { useFeatures } from "../../hooks/useFeatures";
import { useIyopSessions } from "../../hooks/useIyopSessions";
import { usePricing } from "../../hooks/usePricing";
import { useSessions } from "../../hooks/useSessions";
import { useSubscription } from "../../hooks/useSubscription";
import CheckoutModalContent from "../CheckoutModal/CheckoutModalContent";
import { ExplorePlansModal } from "../ExplorePlansModal";

import {
  stepCheckout,
  stepEditScreener,
  stepExternalPanel,
  stepPlaceOrder,
  stepTargeting,
  stepTargetingByot,
} from "./steps";

import styles from "./styles.module.css";

import SvgIconAlert from "./icon-alert.svg";
import imageTestingTime from "./testing-time.svg";
import imageUserInterviews from "./user-interviews.png";

const urlKnowledgeBaseTestingTime =
  "https://help.userbrain.com/help/how-userbrain-and-testingtime-work-together";
const urlKnowledgeBaseUserInterviews =
  "https://help.userbrain.com/help/how-userbrain-and-userinterviews-work-together";
const urlKnowledgeBaseHowToInviteYourOwn =
  "https://help.userbrain.com/help/how-can-i-test-with-my-own-users";
const urlKnowledgeBaseAutomatedTesting =
  "https://help.userbrain.com/help/can-i-have-my-tests-on-repeat";

const tabIdUserbrain = uniqueId("tab");
const tabIdByot = uniqueId("tab");
const tabPanelIdUserbrain = uniqueId("panel");
const tabPanelIdByot = uniqueId("panel");

const repeatTypeSingle = "single";
const repeatTypeWeekly = "weekly";
const repeatTypeBiweekly = "biweekly";
const repeatTypeMonthly = "monthly";

const optionsDevice = ["desktop", "tablet", "mobile"];
const optionsDeviceApp = ["tablet", "mobile"];
const optionsRegion = ["DE", "US", "CA", "GB", "AU", "ZA", "AT"];
const optionsAge = ["1", "2", "3"];
const optionsGender = ["F", "M"];

const explorePlansModeByot = 0;
const explorePlansModeSaveMoney = 1;
const explorePlansModeOrderTesters = 2;

export default function OrderTestsModal({ onClose, isActive }) {
  const [preventModalClose, setPreventModalClose] = useState(false);

  const handleStepChange = useCallback((step) => {
    if (step === stepCheckout) {
      setPreventModalClose(true);
    } else {
      setPreventModalClose(false);
    }
  }, []);

  function handleClose() {
    // prevent default ways to close the modal
    // when step of OrderTestsModalContent is stepCheckout
    if (!preventModalClose) {
      onClose();
    }
  }

  return (
    <Modal2
      isActive={isActive}
      onClose={handleClose}
      maxWidth={"32.5rem"}
      content={
        <OrderTestsModalContent
          onClose={onClose}
          onStepChange={handleStepChange}
        />
      }
    />
  );
}

function OrderTestsModalContent({ onClose, onStepChange }) {
  const [repeatType, setRepeatType] = useState(repeatTypeSingle);

  const [continueOrderLabel, setContinueOrderLabel] = useState("Continue");
  const [continueOrderStep, setContinueOrderStep] = useState(stepCheckout);
  const [continueOrderEnabled, setContinueOrderEnabled] = useState(false);
  const [enableInviteLink, setEnableInviteLink] = useState(undefined);
  const [invitationExampleEmailActive, setInvitationExampleEmailActive] =
    useState(false);
  const [explorePlansMode, setExplorePlansMode] = useState(
    explorePlansModeSaveMoney,
  );

  const refInvitationLinkInput = useRef();

  // Redux
  const dispatch = useDispatch();
  const freeTrial = useFreeTrial();
  const isFreeTrial = useIsFreeTrial();
  const features = useFeatures();
  const test = useSelector((state) => state.orderTesters.test);
  const invitation = test?.invitation;
  const hasUserInvoices = useSelector((state) => state.user.user?.has_invoices);
  // const betaFeaturesEnabled = useSelector(state => !!state.user.user?.beta_features);
  const testFetching = useSelector((state) => state.orderTesters.testFetching);
  const testError = useSelector((state) => state.orderTesters.testError);
  const quantity = useSelector((state) => state.orderTesters.quantity);
  const device = useSelector((state) => state.orderTesters.device);
  const region = useSelector((state) => state.orderTesters.region);
  const age = useSelector((state) => state.orderTesters.age);
  const gender = useSelector((state) => state.orderTesters.gender);
  const screener = useSelector((state) => state.orderTesters.screener);
  const screenerEnabled = useSelector(
    (state) => state.orderTesters.screenerEnabled,
  );
  const initialStep = useSelector((state) => state.orderTesters.initialStep);
  const showWarning = useSelector((state) => state.targeting.showWarning);
  const creditsAvailable = useSelector(
    (state) => state.user.user?.credits.available || 0,
  );
  const currency = useSelector((state) => state.user.user?.currency);
  const subscriptionOwner = useSelector(
    (state) => state.user.user?.subscription_owner,
  );
  const isOwnerOrAdmin = useSelector(selectIsUserOwnerOrAdmin);

  const orderTestsDeliveryError = useSelector(
    (state) => state.orderTests.deliveryError,
  );
  const orderTestsDeliveryErrorMessage = useSelector(
    (state) => state.orderTests.deliveryErrorMessage,
  );

  const deliveryCreateFetching = useSelector(
    (state) => state.delivery.lastRequestFetching,
  );
  const prevDeliveryCreateFetching = usePrevious(deliveryCreateFetching);
  const deliveryCreateError = useSelector(
    (state) => state.delivery.lastRequestError,
  );

  const pricing = usePricing();
  const netPricePerCredit = pricing?.payg / 100;
  const updateInvitationFetching = useSelector(
    (state) => state.tests.updateStatusFetching,
  ); // TODO: Ist im falschen reducer
  const updateInvitationInvitationActive = useSelector(
    (state) => state.tests.updateStatusInvitationActive,
  ); // TODO: Ist im falschen reducer
  const updateInvitationError = useSelector(
    (state) => state.tests.updateStatusError,
  ); // TODO: Ist im falschen reducer
  const prevUpdateInvitationFetching = usePrevious(updateInvitationFetching);
  const iyopSessions = useIyopSessions();
  const sessions = useSessions();
  const currentSubscription = useSubscription();
  const iyopSessionsRefillsAt = iyopSessions?.refills_at;
  const currentPeriodEnd = currentSubscription?.current_period_end;
  const isCancelled = currentSubscription?.cancel_at_period_end;

  let tooltipContentAvailableSessions = `Get up to 100 test sessions with your own customers, users, or team members every month.`;
  if (!isCancelled && iyopSessionsRefillsAt) {
    tooltipContentAvailableSessions += ` Refills on ${formatDateWithoutTime(
      iyopSessionsRefillsAt,
    )}.`;
  } else if (isCancelled && currentPeriodEnd) {
    tooltipContentAvailableSessions = `You have cancelled your plan. The remaining sessions can be used until ${formatDateWithoutTime(
      currentPeriodEnd,
    )}.`;
  }

  const showIyopInterface =
    !!currentSubscription ||
    !!freeTrial?.isActive ||
    iyopSessions?.available > 0;

  const [quantityInput, setQuantityInput] = useState(String(quantity));

  const visibleOptionsRegion = optionsRegion.filter((region) => {
    if (test?.language === "es") {
      return region === "US";
    } else if (test?.language === "de") {
      return region === "DE" || region === "AT";
    } else {
      return region !== "AT";
    }
  });
  const visibleOptionRegionWithDisplayName = visibleOptionsRegion
    .map((code) => [code, getWordingForTargetingValue("region", code)])
    .sort(([, displayNameA], [, displayNameB]) =>
      displayNameA.localeCompare(displayNameB),
    );

  //const [step, setStep] = useState(stepCheckout);
  const [step, setStep] = useState(initialStep);

  const participateUrlString = participateUrl(test);

  const sessionsToUse = Math.min(quantity, sessions?.available);
  const creditsToUse = Math.min(quantity - sessionsToUse, creditsAvailable);
  const creditsToBuy = quantity - creditsToUse - sessionsToUse;
  const priceToPay = netPricePerCredit * creditsToBuy;

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const pathname = location.pathname;

  const isTestView = pathname.match(/\/test\/[0-9]*\/?/i);
  const isTestsView = pathname.match(/\/dashboard\/?$/i);

  const setQuantity = useCallback(
    (value) => {
      dispatch({ type: "ORDER_TESTERS_SET_QUANTITY", quantity: Number(value) });
    },
    [dispatch],
  );

  const setDevice = useCallback(
    (value) => {
      dispatch({ type: "ORDER_TESTERS_SET_DEVICE", device: value });
    },
    [dispatch],
  );

  const setRegion = useCallback(
    (value) => {
      dispatch({ type: "ORDER_TESTERS_SET_REGION", region: value });
    },
    [dispatch],
  );

  const setAge = useCallback(
    (value) => {
      dispatch({ type: "ORDER_TESTERS_SET_AGE", age: value });
    },
    [dispatch],
  );

  const setGender = useCallback(
    (value) => {
      dispatch({ type: "ORDER_TESTERS_SET_GENDER", gender: value });
    },
    [dispatch],
  );

  const setScreener = useCallback(
    (screener) => {
      dispatch({ type: "ORDER_TESTERS_SET_SCREENER", screener });
    },
    [dispatch],
  );

  const setScreenerEnabled = useCallback(
    (screenerEnabled) => {
      dispatch({ type: "ORDER_TESTERS_SET_SCREENER_ENABLED", screenerEnabled });
    },
    [dispatch],
  );

  const screenerNotAvailable = false; // region?.length > 0 && !region.find(region => region === 'US' || region === 'GB');
  useEffect(() => {
    setScreenerEnabled(false);
  }, [screenerNotAvailable, setScreenerEnabled]);

  useEffect(() => {
    setQuantityInput(String(quantity));
  }, [quantity]);

  useEffect(() => {
    onStepChange(step);
  }, [step, onStepChange]);

  useEffect(() => {
    if (
      updateInvitationFetching === false &&
      prevUpdateInvitationFetching === true &&
      !updateInvitationError
    ) {
      if (isTestView) {
        // dispatch({ type: "TEST_REQUEST" });
        const testId = test.id;

        // ! Gleicher Code wie im OrderTestsModal !

        if (pathname.match(new RegExp(`^/test/${testId}(/.*)?$`, "i"))) {
          // If we are on any of the test detail views/tabs reload the test
          // this is needed because test loader does not revalidate the test by default
          dispatch({ type: "TEST_RE_REQUEST" });
        }

        if (updateInvitationInvitationActive === true) {
          if (pathname.match(new RegExp(`/test/${testId}/testers/?$`, "i"))) {
            if (searchParams.size > 0) {
              // Only if we are not on the correct testers page jump to the unfiltered first page
              setSearchParams({}); // will cause loader to refetch
            }
          } else {
            navigate(`/test/${testId}/testers`);
          }
        }
      } else {
        if (updateInvitationInvitationActive) {
          if (location.search === "") {
            dispatch({ type: "TESTS_REQUEST" });
          } else {
            navigate("/dashboard");
          }
        }
      }
    }
  }, [
    test,
    pathname,
    isTestsView,
    isTestView,
    updateInvitationFetching,
    prevUpdateInvitationFetching,
    updateInvitationInvitationActive,
    updateInvitationError,
    navigate,
    location,
    dispatch,
    setSearchParams,
    searchParams.size,
  ]);

  useEffect(() => {
    const status = test?.invitation?.active;
    if (typeof status !== "undefined") {
      setEnableInviteLink(status);
      if (!status) {
        setInvitationExampleEmailActive(false);
      }
    }

    const isVisibleRegion = (region) =>
      visibleOptionsRegion.indexOf(region) !== -1;
    const isNotVisibleRegion = (region) =>
      visibleOptionsRegion.indexOf(region) === -1;

    if (region?.find(isNotVisibleRegion)) {
      const filteredRegions = region.filter(isVisibleRegion);
      if (filteredRegions.length === 0) {
        dispatch({ type: "ORDER_TESTERS_SET_REGION", region: null });
      } else {
        dispatch({ type: "ORDER_TESTERS_SET_REGION", region: filteredRegions });
      }
    }
  }, [test, region, visibleOptionsRegion, dispatch]);

  // Show step success if order was success
  useEffect(() => {
    if (
      !deliveryCreateError &&
      prevDeliveryCreateFetching === true &&
      deliveryCreateFetching === false
    ) {
      // Fetching did stop and success is true
      const testId = test.id;

      // ! Gleicher Code wie im OrderTestsModal !

      if (pathname.match(new RegExp(`^/test/${testId}(/.*)?$`, "i"))) {
        // If we are on any of the test detail views/tabs reload the test
        // this is needed because test loader does not revalidate the test by default
        // silent: false is so that the old state does not blink in for a short time
        dispatch({ type: "TEST_RE_REQUEST", silent: false });
      }

      if (pathname.match(new RegExp(`/test/${testId}/testers/?$`, "i"))) {
        // If we are on the correct testers page jump to the unfiltered first page
        setSearchParams({}); // will cause videos loader to refetch
      } else {
        navigate(`/test/${testId}/testers`);
      }

      onClose();
    }
  }, [
    test,
    pathname,
    deliveryCreateError,
    prevDeliveryCreateFetching,
    deliveryCreateFetching,
    navigate,
    location,
    onClose,
    dispatch,
    setSearchParams,
  ]);

  useEffect(() => {
    // Don't show done if there was an error with the delivery
    if (orderTestsDeliveryError) {
      setContinueOrderEnabled(true);
      setContinueOrderLabel("Continue order");
      setContinueOrderStep(stepPlaceOrder);
    }
  }, [orderTestsDeliveryError, orderTestsDeliveryErrorMessage]);

  // Refresh targeting options when test or targeting changes
  useEffect(() => {
    if (test) {
      // Add screener field only if feature is enabled
      const screenerFields = {};
      screenerFields.screener_active = screenerEnabled;
      screenerFields.screener = convertScreenerToApiFormat(screener);

      dispatch({
        type: "TARGETING_REQUEST",
        testId: test.id,
        device,
        gender,
        region: region?.length > 0 ? region : null,
        age: age?.length > 0 ? age : null,
        language: test.language,
        ...screenerFields,
      });
    }
  }, [dispatch, test, device, gender, region, age, screenerEnabled, screener]);

  // Clear errors when component is mounted
  useEffect(() => {
    dispatch({ type: "DELIVERY_CREATE_CLEAR_ERROR" });
    dispatch({ type: "ORDER_TESTS_CLEAR_ERRORS" });
  }, [dispatch]);

  const {
    showModal: _showExplorePlansModal,
    hideModal: hideExplorePlansModal,
    modal: explorePlansModal,
  } = useModal2(
    <ExplorePlansModal
      onClickChooseStarter={(billingCycle) =>
        handleClickChoosePlan(billingCycle, "starter")
      }
      onClickChoosePro={(billingCycle) =>
        handleClickChoosePlan(billingCycle, "pro")
      }
      onClickChooseAgency={(billingCycle) =>
        handleClickChoosePlan(billingCycle, "agency")
      }
      onClickChoosePayg={handleClickChoosePayg}
      payAsYouGo={
        explorePlansMode === explorePlansModeOrderTesters && {
          sessionsToUse,
          creditsToUse,
          creditsToBuy,
          isFreeTrial,
          hasUserInvoices,
        }
      }
    />,
  );

  function showExplorePlansModal(explorePlansMode) {
    const mode = explorePlansMode ?? explorePlansModeSaveMoney;
    setExplorePlansMode(mode);
    _showExplorePlansModal();
  }

  function handleClickExplorePlansByot() {
    showExplorePlansModal(explorePlansModeByot);
  }

  function handleClickChoosePayg() {
    setStep(stepCheckout);

    // Can be clicked in explore plans or change plan modal, close both
    hideExplorePlansModal();

    // Do not redirect to place order
    setContinueOrderEnabled(false);
  }

  function handleChangeDevice(event) {
    setDevice(event.target.value || null);
  }

  function handleChangeRegion(event) {
    const checked = event.target.checked;
    const value = event.target.value;
    const regionWithoutValue = region.filter((item) => item !== value);
    if (checked) {
      setRegion([...regionWithoutValue, value]);
    } else {
      setRegion(regionWithoutValue);
    }
  }

  function handleChangeRegionEnabled(event) {
    if (event.target.checked) {
      setRegion([]);
    } else {
      setRegion(null);
    }
  }

  function handleChangeAge(event) {
    const checked = event.target.checked;
    const value = event.target.value;
    const ageWithoutValue = age.filter((item) => item !== value);
    if (checked) {
      setAge([...ageWithoutValue, value]);
    } else {
      setAge(ageWithoutValue);
    }
  }

  function handleChangeAgeEnabled(event) {
    if (event.target.checked) {
      setAge([]);
    } else {
      setAge(null);
    }
  }

  function handleChangeGender(event) {
    setGender(event.target.value);
  }

  function handleChangeGenderEnabled(event) {
    if (event.target.checked) {
      const option = optionsGender.find(() => true);
      setGender(option || null);
    } else {
      setGender(null);
    }
  }

  const setupScreeningQuestions = () => {
    if (screener === null) {
      setStep(stepEditScreener);
    } else {
      setScreenerEnabled(true);
    }
  };

  const handleClickAddScreeningQuestions = setupScreeningQuestions;

  function handleChangeScreenerEnabled(event) {
    if (event.target.checked) {
      setupScreeningQuestions();
    } else {
      setScreenerEnabled(false);
    }
  }

  function handleClickEditScreener() {
    setStep(stepEditScreener);
  }

  function handleClickDoneEditScreener(screener) {
    setScreenerEnabled(true);
    setStep(stepTargeting);
    setScreener(screener);
  }

  function handleClickContinue() {
    setStep(stepPlaceOrder);
  }

  function handleClickWantToRecruitViaPartnerPanels() {
    setStep(stepExternalPanel);
  }

  function handleChangeQuantity(event) {
    setQuantityInput(event.target.value);
    setQuantity(Number(event.target.value));
  }

  function handleBlurQuantity() {
    setQuantityInput(String(quantity));
  }

  function handleChangeRepeatType(event) {
    setRepeatType(event.target.value);
  }

  function handleClickPlaceOrder() {
    const targeting = {
      region: region?.length > 0 ? region : null,
      age: age?.length > 0 ? age : null,
      gender,
      device,
      screener: convertScreenerToApiFormat(screener),
      screener_active: screenerEnabled,
    };

    if (creditsToBuy === 0) {
      dispatch({
        type: "DELIVERY_CREATE_REQUEST",
        testId: test.id,
        repeatType,
        videoCount: quantity,
        targeting,
      });
    } else {
      dispatch({
        type: "CHECKOUT_INIT",
        creditCount: creditsToBuy,
        plan: "payg",
        deliveryRequest: {
          testId: test.id,
          repeatType,
          videoCount: quantity,
          targeting,
        },
      });
      if (!currentSubscription?.quantity) {
        showExplorePlansModal(explorePlansModeOrderTesters);
      } else {
        setStep(stepCheckout);
        // Do not redirect to place order
        setContinueOrderEnabled(false);
      }
    }
  }

  function handleClickChangeTargeting() {
    setStep(stepTargeting);
  }

  function handleClickChoosePlan(billingCycle, plan) {
    // New Subscription or single Credits

    dispatch({
      type: "CHECKOUT_INIT",
      creditCount: 0,
      plan,
      billingCycle,
    });
    // Can be clicked in explore plans or change plan modal, close both
    hideExplorePlansModal();

    setStep(stepCheckout);

    // Redirect to to byot after purchase
    setContinueOrderEnabled(true);
    setContinueOrderLabel("Continue");
    if (explorePlansMode === explorePlansModeByot) {
      setContinueOrderStep(stepTargetingByot);
    } else {
      setContinueOrderStep(stepPlaceOrder);
    }
  }

  function handleClickParticipateUrl(event) {
    event.target.select();
  }

  function handleFocusParticipateUrl(event) {
    event.target.select();
  }

  function handleCheckoutContinueOrder() {
    setStep(continueOrderStep);
  }

  function handleEnableInviteLink(e) {
    const checked = e.target.checked;
    setEnableInviteLink(checked);
    if (checked) {
      dispatch({
        type: "TEST_INVITATION_UPDATE_STATUS",
        testId: test.id,
        active: true,
      });
    } else {
      dispatch({
        type: "TEST_INVITATION_UPDATE_STATUS",
        testId: test.id,
        active: false,
      });
    }
  }

  function handleClickShowInvitationExampleEmail() {
    setInvitationExampleEmailActive(true);
  }

  function handleClickHideInvitationExampleEmail() {
    setInvitationExampleEmailActive(false);
  }

  function handleClickCopyInvitationLink() {
    copyTextToClipboard(refInvitationLinkInput.current, dispatch);
  }

  function handleClickCancelEditScreener() {
    if (screener === null) {
      setScreenerEnabled(false);
    }
    setStep(stepTargeting);
  }

  function formatPrice(price) {
    return formatPriceWithCurrency(price, currency);
  }

  let modalLoading = false;
  let modalContent = null;

  if (step === stepEditScreener) {
    modalLoading = testFetching;
    modalContent = test && (
      <>
        <ModalHeader
          heading="Screening Questions"
          onClickBackButton={handleClickCancelEditScreener}
        />
        <ScreenerModalContent
          testId={test.id}
          initialScreener={screener}
          onClickDone={handleClickDoneEditScreener}
          className={styles.modal}
        />
      </>
    );
  } else if (step === stepTargeting || step === stepTargetingByot) {
    modalLoading = testFetching;
    modalContent = test && (
      <>
        <TabbedModalHeader heading="Order testers" onClickCloseIcon={onClose}>
          <TabbedModalHeaderItem
            id={tabIdUserbrain}
            active={step === stepTargeting}
            onClick={() => setStep(stepTargeting)}
            aria-controls={tabPanelIdUserbrain}
          >
            From Userbrain panel
          </TabbedModalHeaderItem>
          <TabbedModalHeaderItem
            id={tabIdByot}
            active={step === stepTargetingByot}
            onClick={() => setStep(stepTargetingByot)}
            aria-controls={tabPanelIdByot}
          >
            Bring your own testers
          </TabbedModalHeaderItem>
        </TabbedModalHeader>

        <div
          id={tabPanelIdUserbrain}
          role="tabpanel"
          aria-labelledby={tabIdUserbrain}
          tabIndex={0}
          hidden={step !== stepTargeting}
          className={`${styles.tabPanel}`}
        >
          <fieldset className={styles.criteria}>
            <legend className={styles.criteriaLegend}>Device</legend>
            <RadioGroup
              value={device ?? ""}
              onChange={handleChangeDevice}
              className={styles.criteriaOptions}
            >
              <RadioGroupItem value={""}>
                {getWordingForTargetingValue("device", null)}
              </RadioGroupItem>
              {(test.type === "app" ? optionsDeviceApp : optionsDevice).map(
                (value) => (
                  <RadioGroupItem key={value} value={value}>
                    {getWordingForTargetingValue("device", value)}
                  </RadioGroupItem>
                ),
              )}
            </RadioGroup>
          </fieldset>

          <fieldset className={styles.criteria}>
            <ToggleButton
              onChange={handleChangeRegionEnabled}
              checked={region !== null}
            >
              Country
            </ToggleButton>
            {region !== null && (
              <div className={styles.criteriaOptions}>
                {visibleOptionRegionWithDisplayName.map(
                  ([code, displayName]) => (
                    <Checkbox
                      className={styles.checkboxItem}
                      key={code}
                      value={code}
                      onChange={handleChangeRegion}
                      checked={region.indexOf(code) !== -1}
                    >
                      <InlineIconImg
                        className={styles.flag}
                        src={`/flags/1x1/${toLower(code)}.svg`}
                        alt={"Flag of " + displayName}
                      />
                      {displayName}
                    </Checkbox>
                  ),
                )}
              </div>
            )}
          </fieldset>

          <fieldset className={styles.criteria}>
            <ToggleButton
              onChange={handleChangeAgeEnabled}
              checked={age !== null}
            >
              Age range
            </ToggleButton>
            {age !== null && (
              <div className={styles.criteriaOptions}>
                {optionsAge.map((value) => (
                  <Checkbox
                    className={styles.checkboxItem}
                    key={value}
                    value={value}
                    onChange={handleChangeAge}
                    checked={age.indexOf(value) !== -1}
                  >
                    {getWordingForTargetingValue("age", value)}
                  </Checkbox>
                ))}
              </div>
            )}
          </fieldset>

          <fieldset className={styles.criteria}>
            <ToggleButton
              onChange={handleChangeGenderEnabled}
              checked={gender !== null}
            >
              Gender
            </ToggleButton>
            <div className={styles.criteriaOptions} hidden={gender === null}>
              <RadioGroup value={gender} onChange={handleChangeGender}>
                {optionsGender.map((value) => (
                  <RadioGroupItem key={value} value={value}>
                    {getWordingForTargetingValue("gender", value)}
                  </RadioGroupItem>
                ))}
              </RadioGroup>
            </div>
          </fieldset>

          <fieldset className={styles.criteria}>
            {screener === null && (
              <ButtonLink onClick={handleClickAddScreeningQuestions}>
                Add screening questions
              </ButtonLink>
            )}
            {screener !== null && (
              <ToggleButton
                onChange={handleChangeScreenerEnabled}
                checked={screenerEnabled === true}
                disabled={screenerNotAvailable}
              >
                Screener
              </ToggleButton>
            )}
            {screenerEnabled === true && (
              <div className={styles.criteriaOptions}>
                {screener && (
                  <InfoBox type={"subtle"} className={styles.screenerPreview}>
                    {screener.questions.map((question, index) => (
                      <div key={index}>
                        <strong>{`Question ${index + 1}`}:</strong>{" "}
                        {question.text}
                      </div>
                    ))}
                    <ButtonLink
                      className={styles.editScreenerLink}
                      onClick={handleClickEditScreener}
                    >
                      Edit
                    </ButtonLink>
                  </InfoBox>
                )}
              </div>
            )}
            {screenerNotAvailable && (
              <Tooltip
                className={styles.repeatTypeTooltip}
                content={
                  "Screening testers is currently only available for target audiences that include United Kingdom or United States."
                }
              />
            )}
          </fieldset>

          {showWarning && (
            <InfoBox className={styles.targetingWarning}>
              Getting results might take longer than usual using these settings.
            </InfoBox>
          )}

          <div className={styles.footer}>
            <ButtonGroup align={"right"} className={styles.footerButtons}>
              <ButtonPrimary onClick={handleClickContinue}>
                Continue
              </ButtonPrimary>
            </ButtonGroup>
          </div>
        </div>

        <div
          id={tabPanelIdByot}
          role="tabpanel"
          aria-labelledby={tabIdByot}
          tabIndex={0}
          hidden={step !== stepTargetingByot}
          className={`${styles.tabPanel} ${styles.tabPanelByot}`}
        >
          {showIyopInterface && (
            <>
              {/* 
                The IYOP interface is visible for 
                - Everyone with a subscription
                - Everyone with a free trial
                - If we manually have set the iyopSessions to a value > 0
              */}
              {freeTrial.isActive && !currentSubscription && (
                <FeatureGateMessage
                  showLockIcon={false}
                  title={
                    <>
                      Available during your{" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        14-day free trial
                      </span>
                    </>
                  }
                  onClickUpgrade={handleClickExplorePlansByot}
                >
                  Upgrade to one of our plans to continue using this feature
                  after your trial period ends.
                </FeatureGateMessage>
              )}

              <p className={styles.invitationDescription}>
                Share a link to this study with your own testers. We’ll help
                them start and complete their session. Testers invited by you
                won’t be compensated by Userbrain. You will get the email
                address from everyone who completes your test via the invite
                link.
              </p>

              <ButtonLink
                className={styles.linkRecruitViaPartnerPanels}
                onClick={handleClickWantToRecruitViaPartnerPanels}
              >
                Want to recruit users via our partner panels?
              </ButtonLink>

              <p className={styles.availableSessions}>
                Available sessions: {iyopSessions?.available}{" "}
                <Tooltip content={tooltipContentAvailableSessions} />
              </p>

              {typeof enableInviteLink !== "undefined" && (
                <ToggleButton
                  className={styles.enableInvitationLink}
                  onChange={handleEnableInviteLink}
                  checked={enableInviteLink}
                >
                  Enable invite link
                </ToggleButton>
              )}

              {invitation?.active && (
                <>
                  <div className={styles.invitationLinkWrapper}>
                    <InputText
                      autoFocus
                      innerRef={refInvitationLinkInput}
                      onClick={handleClickParticipateUrl}
                      onFocus={handleFocusParticipateUrl}
                      value={participateUrlString}
                      readOnly
                    />
                    <Button
                      className={styles.invitationLinkCopyButton}
                      onClick={handleClickCopyInvitationLink}
                    >
                      Copy link
                    </Button>
                  </div>
                  <div className={styles.invitationExampleEmailToggle}>
                    {invitationExampleEmailActive === false && (
                      <ButtonText
                        onClick={handleClickShowInvitationExampleEmail}
                      >
                        Show invitation example
                      </ButtonText>
                    )}
                    {invitationExampleEmailActive && (
                      <>
                        <div className={styles.exampleEmail}>
                          Hello,
                          <br />
                          <br />
                          you have been invited to participate in a usability
                          study.
                          <br />
                          <br />
                          To get started please click on the link below:
                          <br />
                          <a
                            href={participateUrlString}
                            target={"_blank"}
                            rel="noopener noreferrer"
                          >
                            {participateUrlString}
                          </a>
                          <br />
                          <br />
                          Thanks,
                          <br />
                          The Researcher
                        </div>
                        <ButtonText
                          onClick={handleClickHideInvitationExampleEmail}
                        >
                          Hide example
                        </ButtonText>
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}
          {!showIyopInterface && (
            <section className={styles.explorePlansBox}>
              {/* When the iyop interface is not shown tell the user to upgrade to a paid plan to use IYOP */}
              {isOwnerOrAdmin ? (
                <>
                  <h1>Pick a plan</h1>
                  <p>
                    Invite real customers or do trial tests with colleagues,
                    etc. — just share a link with the people you want to test
                    with.
                    <br />
                    <a
                      href={urlKnowledgeBaseHowToInviteYourOwn}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </p>
                  <ButtonPrimary
                    className={styles.explorePlansBoxButton}
                    onClick={handleClickExplorePlansByot}
                  >
                    Explore our plans
                  </ButtonPrimary>
                </>
              ) : (
                <>
                  <h1>Contact account owner</h1>
                  <p>
                    You can’t invite your own testers with your current plan.
                    Contact your account owner ({subscriptionOwner}) to upgrade.
                  </p>
                  <ButtonPrimary
                    className={styles.explorePlansBoxButton}
                    href={`mailto:${subscriptionOwner}`}
                  >
                    Contact account owner
                  </ButtonPrimary>
                </>
              )}
            </section>
          )}
        </div>
      </>
    );
  } else if (step === stepPlaceOrder) {
    modalLoading = testFetching;
    const isBlockerOverlay = test.is_sample === true;

    const errorMessage =
      deliveryCreateError?.type === "not_enough_credits"
        ? `Not enough credits`
        : deliveryCreateError?.message;

    modalContent = test && (
      <>
        <ModalHeader
          heading={"Order Userbrain testers"}
          icon={solid("users")}
          onClickCloseIcon={isBlockerOverlay ? onClose : undefined}
        />

        <div className={`${styles.modalContentForm}`}>
          <div className={styles.modalContentFormContentWrapper}>
            <Stack>
              {deliveryCreateError && <ErrorMessage message={errorMessage} />}

              <TesterQuantitySelection
                onChange={handleChangeQuantity}
                onBlur={handleBlurQuantity}
                value={quantityInput}
              />
            </Stack>
            <TargetingSummary
              device={device}
              region={region}
              age={age}
              gender={gender}
              screenerEnabled={screenerEnabled}
            />
            <ButtonLink
              className={styles.changeTargeting}
              onClick={handleClickChangeTargeting}
            >
              Change criteria
            </ButtonLink>
            {test.is_sample !== true && (
              <>
                <Summary
                  formatPrice={formatPrice}
                  quantity={quantity}
                  creditsAvailable={creditsAvailable}
                  subscriptionOwner={subscriptionOwner}
                  isOwnerOrAdmin={isOwnerOrAdmin}
                  sessions={sessions}
                  sessionsToUse={sessionsToUse}
                  creditsToUse={creditsToUse}
                  creditsToBuy={creditsToBuy}
                  priceToPay={priceToPay}
                  isFreeTrial={isFreeTrial}
                  hasUserInvoices={hasUserInvoices}
                />
                <Footer
                  onClose={onClose}
                  repeatType={repeatType}
                  features={features}
                  quantity={quantity}
                  isOwnerOrAdmin={isOwnerOrAdmin}
                  deliveryCreateFetching={deliveryCreateFetching}
                  creditsToBuy={creditsToBuy}
                  onChangeRepeatType={handleChangeRepeatType}
                  onClickPlaceOrder={handleClickPlaceOrder}
                />
              </>
            )}
          </div>
          {isBlockerOverlay && <SampleTestBlocker />}
        </div>
      </>
    );
  } else if (step === stepCheckout) {
    modalContent = (
      <CheckoutModalContent
        onClose={onClose}
        showContinueOrder={continueOrderEnabled}
        continueOrderLabel={continueOrderLabel}
        onContinueOrder={handleCheckoutContinueOrder}
      />
    );
  } else if (step === stepExternalPanel) {
    modalContent = (
      <>
        <ModalHeader
          heading={"Order testers"}
          onClickBackButton={() => setStep(stepTargetingByot)}
          onClickCloseIcon={onClose}
          additionalInfoText={
            "Recruit more specific demographics with the help of our partners and invite them to your study."
          }
        />
        <div className={styles.modalContent}>
          <div className={styles.targetingPartner}>
            <a
              href={
                "https://www.testingtime.com/?utm_source=partnership&utm_medium=app&utm_campaign=userbrain&customer_referral=userbrain"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.logoTestingTime}
                src={imageTestingTime}
                alt="Logo: Testing Time"
              />
            </a>
            <p>
              {formatNumber(850000)}+ vetted testers for unmoderated studies,
              multiple screener options.
            </p>
            <Button
              className={styles.partnerLearnMore}
              href={urlKnowledgeBaseTestingTime}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </Button>
          </div>

          <div className={styles.targetingPartner}>
            <a
              href={"https://www.userinterviews.com/lp/userbrain"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.logoUserInterviews}
                src={imageUserInterviews}
                alt="Logo: User Interviews"
              />
            </a>
            <p>
              {formatNumber(400000)}+ vetted testers, 500+ occupations and other
              criteria to target.
            </p>
            <Button
              className={styles.partnerLearnMore}
              href={urlKnowledgeBaseUserInterviews}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </Button>
          </div>
        </div>
        <div className={styles.footerTargetingPartners}>
          <a
            className={styles.targetingHelp}
            href="mailto:support@userbrain.com"
          >
            Need help? Contact us
          </a>
          <ButtonPrimary onClick={() => setStep(stepTargetingByot)}>
            Done
          </ButtonPrimary>
        </div>
      </>
    );
  }

  // Todo: find a solution to do this with the current modal approach
  // const preventClose = step === stepCheckout;

  if (testError) {
    return (
      <>
        <ModalHeader
          heading={"Order testers"}
          icon={solid("users")}
          onClickCloseIcon={onClose}
        />
        <div className={styles.modalContent}>
          <ErrorMessage message={testError.message} />
        </div>
      </>
    );
  }

  return (
    <>
      {modalLoading ? <Spinner /> : modalContent}
      {explorePlansModal}
    </>
  );
}

function Summary(props) {
  // If what the available amount is less than expected, the reason is
  // that the user has not received the free trial testers.
  // And never show this info if the user already has paid
  const showHaventReceivedTrialSessionsInfo =
    props.isFreeTrial &&
    !props.hasUserInvoices &&
    props.sessions?.available <
      props.sessions?.included - props.sessions?.used_this_month;

  return (
    <div className={styles.summary}>
      <div className={styles.summaryItem}>
        <div className={styles.summaryItemLabel}>Your order</div>
        <div className={styles.summaryItemValue}>
          {props.quantity} {props.quantity === 1 ? "tester" : "testers"}{" "}
        </div>
      </div>

      {props.sessionsToUse > 0 && (
        <div className={styles.summaryItem}>
          <div className={styles.summaryItemLabel}>
            {getFromYourPlanWording(props.hasUserInvoices, props.isFreeTrial)}
            <span className={styles.summarySecondaryText}>
              {" "}
              ·&nbsp;{props.sessions?.available}&nbsp;
              {props.sessions?.available === 1 ? "tester" : "testers"} available
            </span>
          </div>
          <div className={styles.summaryItemValue}>
            {props.sessionsToUse}{" "}
            {props.sessionsToUse === 1 ? "tester" : "testers"}
          </div>
        </div>
      )}

      {props.creditsToUse > 0 && (
        <div className={styles.summaryItem}>
          <div className={styles.summaryItemLabel}>
            From your credits
            <span className={styles.summarySecondaryText}>
              {" "}
              ·&nbsp;{props.creditsAvailable}&nbsp;
              {props.creditsAvailable === 1 ? "tester" : "testers"} available
            </span>
          </div>
          <div className={styles.summaryItemValue}>
            {props.creditsToUse}{" "}
            {props.creditsToUse === 1 ? "tester" : "testers"}
          </div>
        </div>
      )}

      {props.creditsToBuy > 0 && (
        <div className={styles.summaryItem}>
          <div className={styles.summaryItemLabel}>
            {props.creditsToBuy}{" "}
            {props.creditsToBuy === 1 ? "tester" : "testers"}
            <span className={styles.summarySecondaryText}>
              {" "}
              &times;&nbsp;
              {props.formatPrice(props.priceToPay / props.creditsToBuy)} per
              tester
            </span>
          </div>
          <div className={styles.summaryItemValue}>
            {props.formatPrice(props.priceToPay)}
          </div>
        </div>
      )}

      <div className={cn(styles.summaryItem, styles.summaryItem_total)}>
        <div className={styles.summaryItemLabel}>Total cost</div>
        <div className={styles.summaryItemValue}>
          {props.formatPrice(props.priceToPay)}
        </div>
      </div>

      {showHaventReceivedTrialSessionsInfo && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={urlKnowledgeBaseHaventReceivedTrialSessions}
        >
          Why haven't I received my free testers?
        </a>
      )}

      {props.creditsToBuy > 0 && !props.isOwnerOrAdmin && (
        <p className={styles.contactOwnerToBuy}>
          Please contact the{" "}
          <a href={`mailto:${props.subscriptionOwner}`}>account owner</a> to buy
          more credits.{" "}
        </p>
      )}
    </div>
  );
}

function Footer(props) {
  return (
    <div className={styles.footer}>
      {props.features?.automatedTesting ? (
        <div className={styles.repeatType}>
          <DropDown
            className={styles.repeatTypeDropDown}
            value={props.repeatType}
            onChange={props.onChangeRepeatType}
          >
            <DropDownItem value={repeatTypeSingle}>
              Perform only once
            </DropDownItem>
            <DropDownItem value={repeatTypeWeekly}>
              Repeat {getPeriodNameByIdendtifier(repeatTypeWeekly)}
            </DropDownItem>
            <DropDownItem value={repeatTypeBiweekly}>
              Repeat {getPeriodNameByIdendtifier(repeatTypeBiweekly)}
            </DropDownItem>
            <DropDownItem value={repeatTypeMonthly}>
              Repeat {getPeriodNameByIdendtifier(repeatTypeMonthly)}
            </DropDownItem>
          </DropDown>
          <Tooltip
            className={styles.repeatTypeTooltip}
            content={
              "Set up your test to automatically repeat as long as you have enough testers or credits to perform a complete round of testing. You can always manually stop your test."
            }
          />
        </div>
      ) : (
        <div className={styles.repeatTypeNoSubscription}>
          <Tooltip
            content={
              <>
                Set up your test to automatically repeat every week, every 2
                weeks or once a month.
                <br />
                <br />
                <i>Only available with one of our plans.</i>
              </>
            }
          >
            <div className={styles.repeatTypeNoSubscriptionTooltipText}>
              Automated testing
            </div>
          </Tooltip>
          <br />
          <a
            className={styles.repeatTypeNoSubscriptionLearnMore}
            href={urlKnowledgeBaseAutomatedTesting}
            rel="noreferrer"
            target={"_blank"}
          >
            Learn more
            <span className={styles.repeatTypeNoSubscriptionLearnMoreIcon}>
              <FontAwesomeIcon icon={regular("external-link")} />
            </span>
          </a>
        </div>
      )}

      <ButtonGroup align={"right"} className={styles.footerButtons}>
        <ButtonPrimary
          onClick={props.onClickPlaceOrder}
          disabled={
            props.quantity <= 0 ||
            props.deliveryCreateFetching ||
            (props.creditsToBuy > 0 && !props.isOwnerOrAdmin)
          }
        >
          {props.deliveryCreateFetching
            ? "Sending…"
            : props.creditsToBuy > 0
            ? "Continue"
            : "Place order"}
        </ButtonPrimary>
        <Button onClick={props.onClose}>Cancel</Button>
      </ButtonGroup>
    </div>
  );
}

function TargetingSummary({ device, region, age, gender, screenerEnabled }) {
  return (
    <div className={styles.targetingSummary}>
      <div className={styles.targetingSummaryItem}>
        {getWordingForTargetingValue("device", device)}
      </div>
      {region?.map((regionItem, index) => (
        <div className={styles.targetingSummaryItem} key={index}>
          {getWordingForTargetingValue("region", regionItem)}
        </div>
      ))}
      {age?.map((ageItem, index) => (
        <div className={styles.targetingSummaryItem} key={index}>
          {getWordingForTargetingValue("age", ageItem)}
        </div>
      ))}
      {gender && (
        <div className={styles.targetingSummaryItem}>
          {getWordingForTargetingValue("gender", gender)}
        </div>
      )}
      {screenerEnabled && (
        <div className={styles.targetingSummaryItem}>Screener</div>
      )}
    </div>
  );
}

function SampleTestBlocker() {
  const dispatch = useDispatch();

  function handleClickCreateNewTest() {
    dispatch({ type: "GLOBAL_MODAL_OPEN", modal: "createTest" });
  }

  return (
    <div className={styles.sampleTestBlocker}>
      <div className={styles.sampleTestBlockerCard}>
        <img
          className={styles.sampeTestBlockerIcon}
          src={SvgIconAlert}
          alt="Alert icon"
        />
        <div className={styles.sampleTestBlockerTitle}>
          Testers cannot be ordered for example tests
        </div>
        <div className={styles.sampleTestBlockerDescription}>
          This is just an example test to demonstrate how Userbrain works. To
          order testers, please create a new test.
        </div>
        <ButtonPrimary
          className={styles.sampleTestBlockerButton}
          onClick={handleClickCreateNewTest}
        >
          Create new test
        </ButtonPrimary>
      </div>
    </div>
  );
}
