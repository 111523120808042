import { ButtonLink, ButtonPrimary } from "@/design-system";
import { advertedPoolSize } from "@/helpers";
import { Modal2 } from "../Modal";

import styles from "./styles.module.css";

import imgHeader from "./header.jpg";
import imgHeaderIntroduced from "./header-introduced.jpg";

export default function ModalWelcomeFreeTrial({
  onClose,
  isActive,
  welcomeMessageType,
  ...contentProps
}: {
  onClose: () => void;
  isActive: boolean;
  welcomeMessageType: "freeplan" | "freetrial";
} & Omit<ContentProps, "onClickClose">) {
  return (
    <Modal2
      onClose={onClose}
      isActive={isActive}
      maxWidth={"32.5rem"}
      overflowHidden
      content={
        welcomeMessageType === "freeplan" ? (
          <ModalWelcomeFreeTrialContentFreePlan
            onClickClose={onClose}
            {...contentProps}
          />
        ) : (
          <ModalWelcomeFreeTrialContentFreeTrial
            onClickClose={onClose}
            {...contentProps}
          />
        )
      }
    />
  );
}

type ContentProps = {
  onClickClose: () => void;
} & FooterProps;

export function ModalWelcomeFreeTrialContentFreePlan({
  onClickCreateYourFirstTestNow,
  onClickStartExploring,
  onClickClose,
}: ContentProps) {
  return (
    <>
      <CloseButton onClick={onClickClose} />
      <img
        className={styles.image}
        src={imgHeaderIntroduced}
        alt={"A stylized and floating hand pointing upwards"}
      />
      <div className={styles.content}>
        <h1 className={styles.title}>Welcome to Userbrain! 🎉</h1>
        <div className={styles.introduction}>
          Thanks for joining us. You’re all set with your Free Plan.
        </div>
        <div className={styles.featuresListIntroduction}>
          PLUS, you get full access to everything Userbrain offers for 14 days:
        </div>
        <FeatureList />
        <div className={styles.disclaimer}>
          After 14 days, you’ll automatically continue on the Free Plan, with
          ongoing access to test with up to 5 of your own testers each month.
        </div>
        <Footer
          onClickCreateYourFirstTestNow={onClickCreateYourFirstTestNow}
          onClickStartExploring={onClickStartExploring}
        />
      </div>
    </>
  );
}

export function ModalWelcomeFreeTrialContentFreeTrial({
  onClickCreateYourFirstTestNow,
  onClickStartExploring,
  onClickClose,
}: ContentProps) {
  return (
    <>
      <CloseButton onClick={onClickClose} />
      <img
        className={styles.image}
        src={imgHeader}
        alt={"An arm protruding from a hole in a wall showing thumbs up"}
      />
      <div className={styles.content}>
        <h1 className={styles.title}>Welcome to Userbrain! 🎉</h1>
        <div className={styles.introduction}>
          Thanks for joining us. You’re all set with your 14-day free trial to
          explore everything Userbrain can do.
        </div>
        <div className={styles.featuresListIntroduction}>
          Here’s what you get for the next 14 days:
        </div>
        <FeatureList />
        <div className={styles.disclaimer}>
          After 14 days, you’ll automatically switch to the Free Plan, with
          ongoing access to test with up to 5 of your own testers each month.
        </div>
        <Footer
          onClickCreateYourFirstTestNow={onClickCreateYourFirstTestNow}
          onClickStartExploring={onClickStartExploring}
        />
      </div>
    </>
  );
}

type FooterProps = {
  onClickStartExploring: () => void;
  onClickCreateYourFirstTestNow: () => void;
};

function Footer({
  onClickCreateYourFirstTestNow,
  onClickStartExploring,
}: FooterProps) {
  return (
    <>
      <ButtonPrimary
        onClick={onClickCreateYourFirstTestNow}
        className={styles.footerButton}
      >
        Create Your First Test Now
      </ButtonPrimary>
      <small className={styles.footerFinePrint}>
        Or, feel free to{" "}
        <ButtonLink onClick={onClickStartExploring}>
          explore Userbrain
        </ButtonLink>{" "}
        and see what’s possible!
      </small>
    </>
  );
}

function FeatureList() {
  return (
    <ul className={styles.featureList}>
      <li>
        <strong>Recruit two free testers</strong> from our pool of{" "}
        {advertedPoolSize} testers
      </li>
      <li>
        <strong>All features unlocked,</strong> including AI Insights,
        Transcripts, and more
      </li>
      <li>
        <strong>Unlimited Team collaboration:</strong> Invite others to view
        results and leave comments
      </li>
    </ul>
  );
}

function CloseButton({ onClick }: { onClick: () => void }) {
  return (
    <button className={styles.closeButton} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M3.3335 3.33337L12.6668 12.6667M12.6668 3.33337L3.3335 12.6667"
          stroke="#0A0A2D"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  );
}
