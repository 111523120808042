import { Reducer } from "@reduxjs/toolkit";

const WELCOME_MESSAGE_SHOW = "WELCOME_MESSAGE_SHOW";
const WELCOME_MESSAGE_HIDE = "WELCOME_MESSAGE_HIDE";

type WelcomeMessageState = {
  active: boolean;
  type: "freetrial" | "freeplan" | null;
  hasReceivedSessions: boolean | null;
};

const initialState = {
  active: false,
  type: null,
  hasReceivedSessions: null,
};

export const welcomeMessageReducer: Reducer<WelcomeMessageState> = function (
  state = initialState,
  action,
) {
  switch (action.type) {
    case WELCOME_MESSAGE_SHOW:
      return {
        ...state,
        active: true,
        type: action.welcomeMessageType ?? null,
        hasReceivedSessions: action.hasReceivedSessions ?? null,
      };
    case WELCOME_MESSAGE_HIDE:
      return { ...state, active: false };
    default:
      return state;
  }
};
