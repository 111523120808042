import { useState } from "react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";

import {
  Alert,
  Button,
  ButtonPrimary,
  Checkbox,
  DropDown,
  DropDownItem,
  ErrorMessage,
  FieldHelp,
  FormField,
  InputText,
  Stack,
  Tooltip,
} from "@/design-system";
import { useModal2 } from "@/hooks/useModal2";
import { ApiError } from "@/reducers/types";
import {
  testUrlErrorStringFilter,
  testUrlFieldFeedbackFilter,
} from "@/utils/filters";
import {
  addHttp,
  getLanguageNameByCode,
  isValidURL,
  noop,
} from "../../helpers";
import {
  AiCreatorLoadingBanner,
  AiCreatorPrompt,
  AiCreatorSkeleton,
  SelectCreationType,
  StartOverBanner,
} from "../AiTaskCreator";
import ButtonGroup from "../ButtonGroup";
import ConfirmModal from "../ConfirmModal";
import TaskEditor from "../TaskEditor";

import { AiCreatorStatusIndicator } from "./AiCreatorStatusIndicator";
import { AiTaskCreatorFeedback } from "./AiTaskCreatorFeedback";
import { CreationType, TestAutoPrompt, TestType, URL } from "./constants";

import styles from "./styles.module.css";

/*

- TODO: Typeform integration _blank tab + email + reaction + aittest_id in url.

- TODO: Test Firefox
- TODO: Test Safari

- TODO: Make sure icons display correctly.
- TODO [cleanup]: Smiley face icon simplify eyes
- TODO [cleanup]: Improve semantics.

*/

type TestForm2Props = {
  testType: TestType;
  errorMessage: string;
  fieldFeedback: {
    title?: string;
    url?: string[];
    language?: string;
  };
  language: "en" | "de" | "es";
  title: string;
  url: string;
  task: {}[];
  originalTask: string;
  className: string;
  urlCheckerDone: boolean;
  urlCheckerOk: boolean;
  urlCheckerMessage: string;
  urlTypeformAiTaskCreatorSadEmotion: string;
  urlTypeformAiTaskCreatorNeutralEmotion: string;
  urlTypeformAiTaskCreatorHappyEmotion: string;
  onChangeTitle: (e: any) => void;
  onChangeUrl: (e: any) => void;
  onChangeLanguage: (e: any) => void;
  onChangeTask: (task: any) => void;
  onOverwriteUrlCheck: () => void;
  onClickPreviewTaskStep: () => void;
  onAutoRequest: (prompt: TestAutoPrompt) => void;
  onStartOver: () => void;
  isAiTestSetupFetching: boolean;
  autoTestSetupError: null | string;
  aiTestSetupPrompt: null | TestAutoPrompt;
  isAiTestSetupSuccess: boolean;
  aiTestSetupError: null | ApiError;
  creationType: CreationType | null;
  onSelectCreationType: (creationType: CreationType | null) => void;
  isExistingTest: boolean;
  isDuplicateTest: boolean;
};

export default function TestForm2(props: TestForm2Props) {
  const {
    className,
    errorMessage,
    fieldFeedback,
    isAiTestSetupFetching,
    isAiTestSetupSuccess,
    aiTestSetupError,
    language,
    onAutoRequest,
    onChangeLanguage,
    onChangeTask,
    onChangeTitle,
    onChangeUrl,
    onClickPreviewTaskStep,
    onOverwriteUrlCheck,
    onStartOver,
    originalTask,
    task,
    testType,
    title,
    url,
    urlCheckerDone,
    urlCheckerMessage,
    urlCheckerOk,
    urlTypeformAiTaskCreatorSadEmotion,
    urlTypeformAiTaskCreatorNeutralEmotion,
    urlTypeformAiTaskCreatorHappyEmotion,
    creationType,
    onSelectCreationType,
    isExistingTest,
  } = props;

  /*





  */

  const [isDisplayProceedOnBadLinkModal, setIsDisplayProceeedOnBadLinkModal] =
    useState(false);
  const [isProceedOnBadLink, setIsProceedOnBadLink] = useState(false);
  const parsedUrlErrorMessage = testUrlErrorStringFilter(errorMessage);
  const { placeholder: urlInputPlaceholder, fieldHelp: urlFieldHelp } =
    getUrlInputProps(testType, url);
  const isUrlApp = getIsUrlApp(url);
  const promptFieldFeedback = aiTestSetupError
    ? Object.values(aiTestSetupError.fieldFeedback).flat(Infinity)
    : [];
  const [autoDescription, setAutoDescription] = useState<string>("");

  /*


    HANDLERS


  */

  function startAiTaskCreation() {
    onAutoRequest({
      type: testType,
      language,
      url,
      description: autoDescription,
    });
  }

  const handleChangeTitle = (e: any) => {
    onChangeTitle(e);
  };

  const handleChangeUrl = (e: any) => {
    onChangeUrl(e);
  };

  const handleChangeTask = (task: any) => {
    onChangeTask(task);
  };

  const handleChangeTaskWithProduce = (producer: (args: any) => any) => {
    onChangeTask(producer(task));
  };

  const handleClickProceedAnyway = () => {
    setIsDisplayProceeedOnBadLinkModal(true);
    setIsProceedOnBadLink(false);
  };

  const handleClickProceedAnywayConfirm = () => {
    if (isProceedOnBadLink) {
      onOverwriteUrlCheck();
      setIsDisplayProceeedOnBadLinkModal(false);
    }
  };

  const handleCloseProceedAnywayConfirmationDialog = () => {
    setIsDisplayProceeedOnBadLinkModal(false);
  };

  const handleChangeProceedAnywayConfirmationDialogCheckbox = (e: any) => {
    setIsProceedOnBadLink(e.target.checked);
  };

  /*


    ELEMENTS


  */

  const basicInformationInputs = (
    <Stack className={`card ${styles.card}`}>
      {parsedUrlErrorMessage && (
        <ErrorMessage message={parsedUrlErrorMessage} />
      )}
      <FormField
        autoFocus={!isExistingTest}
        errorMessage={fieldFeedback?.title}
        label={"Test Name"}
      >
        <InputText
          onChange={handleChangeTitle}
          id={"create-test-title"}
          value={title}
          fullWidth
          autoFocus={!title}
        />
        <FieldHelp>
          For internal use only (your testers won’t see this)
        </FieldHelp>
      </FormField>

      <FormField
        label={`Test URL`}
        errorMessage={fieldFeedback?.url?.map((msg) =>
          testUrlFieldFeedbackFilter(msg, url, handleClickProceedAnyway),
        )}
        htmlFor={"create-test-url"}
      >
        {isUrlApp && testType !== "app" && (
          <div className={styles.appTestingInfo}>
            <FontAwesomeIcon
              icon={regular("info-circle")}
              fixedWidth
              className={styles.appTestingInfoIcon}
            />
            If you want to test an app, please create a new test and select the
            test type "App".
          </div>
        )}
        <div className={styles.inputWithUrlChecker}>
          <InputText
            className={styles.inputUrl}
            value={url}
            type={"url"}
            id={"create-test-url"}
            placeholder={urlInputPlaceholder}
            onChange={handleChangeUrl}
            fullWidth
          />
          <div className={styles.urlChecker}>
            {urlCheckerDone === true && urlCheckerOk === true && (
              <FontAwesomeIcon
                className={cn(
                  styles.urlCheckerIcon,
                  styles.urlCheckerIcon_success,
                )}
                icon={solid("circle-check")}
              />
            )}
            {urlCheckerDone === true && urlCheckerOk === false && (
              <Tooltip content={urlCheckerMessage}>
                <FontAwesomeIcon
                  className={cn(
                    styles.urlCheckerIcon,
                    styles.urlCheckerIcon_error,
                  )}
                  icon={solid("circle-xmark")}
                />
              </Tooltip>
            )}
          </div>
        </div>
        <FieldHelp>{urlFieldHelp}</FieldHelp>
      </FormField>

      <FormField label={"Language"} errorMessage={fieldFeedback?.language}>
        <DropDown
          value={language}
          onChange={(e: any) => onChangeLanguage(e.target.value)}
        >
          <DropDownItem value={"en"}>
            {getLanguageNameByCode("en")}
          </DropDownItem>
          <DropDownItem value={"de"}>
            {getLanguageNameByCode("de")}
          </DropDownItem>
          <DropDownItem value={"es"}>
            {getLanguageNameByCode("es")}
          </DropDownItem>
        </DropDown>
        <FieldHelp>
          The language your testers will use to speak and express their thoughts
        </FieldHelp>
      </FormField>

      <FormField label={"Length"} fullWidth>
        <div className={styles.lengthFakeInput}>5 to 20 minutes</div>
        <FieldHelp>
          Need longer tests?{" "}
          <a
            href="https://help.userbrain.com/help/how-to-run-longer-tests"
            rel="noreferrer"
            target="_blank"
          >
            Learn more
          </a>
        </FieldHelp>
      </FormField>
    </Stack>
  );

  const badLinkModal = (
    <Alert
      onClose={handleCloseProceedAnywayConfirmationDialog}
      actions={
        // @ts-ignore
        <ButtonGroup align={"right"}>
          <ButtonPrimary
            disabled={!isProceedOnBadLink}
            onClick={handleClickProceedAnywayConfirm}
          >
            OK
          </ButtonPrimary>
          <Button onClick={handleCloseProceedAnywayConfirmationDialog}>
            Cancel
          </Button>
        </ButtonGroup>
      }
    >
      <p style={{ marginTop: 0 }}>
        Our automatic link checker thinks this URL is offline. Are you sure you
        want to proceed anyway?
      </p>
      <div>
        <Checkbox
          checked={isProceedOnBadLink}
          onChange={handleChangeProceedAnywayConfirmationDialogCheckbox}
        >
          I understand that videos will not be replaced if testers can't open
          this URL
        </Checkbox>
      </div>
    </Alert>
  );

  const {
    hideModal: hideStartOverModal,
    modal: confirmStartOverModal,
    showModal: showStartOverModal,
  } = useModal2(
    <ConfirmModal
      isActive
      labelConfirm="Start over and delete tasks"
      onConfirm={() => {
        onStartOver();
        hideStartOverModal();
      }}
      onClose={noop}
    >
      <div className={styles.modalTitle}>Sure you want to start over?</div>
      <div className={styles.modalDescription}>
        Your current tasks will be deleted. This action cannot be undone. Do you
        want to proceed?
      </div>
    </ConfirmModal>,
  );

  return (
    <section className={className}>
      <AiCreatorStatusIndicator
        isHidden={task === null}
        isAiTestSetupFetching={isAiTestSetupFetching}
        isAiTestSetupSuccess={isAiTestSetupSuccess}
      />

      {confirmStartOverModal}
      {basicInformationInputs}
      {isDisplayProceedOnBadLinkModal && badLinkModal}

      <hr className={styles.divider} />

      <h2 className={styles.headingTestInstructions}>Tasks</h2>
      <p className={styles.subHeadingTestInstructions}>
        Tell testers what you want them to do during their test sessions.
      </p>

      {task === null && creationType === null && (
        <SelectCreationType
          className={styles.spaceLg}
          onSelectCreationType={onSelectCreationType}
        />
      )}

      {(creationType === "scratch" ||
        creationType === "template" ||
        (creationType === "auto" && task !== null && !isAiTestSetupFetching) ||
        (creationType === "auto" && task === null)) && (
        <StartOverBanner
          isButtonDisabled={isAiTestSetupFetching}
          creationType={creationType}
          onClickStartOver={task === null ? onStartOver : showStartOverModal}
          className={styles.spaceSm}
        />
      )}

      {creationType === "auto" && task === null && (
        <AiCreatorPrompt
          type={testType}
          isLoading={isAiTestSetupFetching}
          description={autoDescription}
          onChangeDescription={setAutoDescription}
          onClickCreate={startAiTaskCreation}
          fieldFeedback={promptFieldFeedback as string[]}
          errorMessage={aiTestSetupError?.message}
          className={styles.spaceMd}
        />
      )}

      {task !== null && (
        <>
          {isAiTestSetupFetching === true && (
            <AiCreatorLoadingBanner className={styles.autoModeLoading} />
          )}
          <TaskEditor
            fieldFeedback={fieldFeedback}
            onChange={handleChangeTask}
            onChangeWithProduce={handleChangeTaskWithProduce}
            onClickPreviewTaskStep={onClickPreviewTaskStep}
            steps={task}
            originalSteps={originalTask}
            language={language}
            testType={testType}
            isDisabled={isAiTestSetupFetching}
          />
          {isAiTestSetupFetching === true && (
            <AiCreatorSkeleton className={styles.autoModeSkeleton} />
          )}
        </>
      )}

      {isAiTestSetupFetching === false &&
        // isExistingTest === false && // I think creationType === "auto" implies isExistingTest === false
        creationType === "auto" &&
        task !== null && (
          <AiTaskCreatorFeedback
            className={styles.spaceSm}
            urlSadEmotion={urlTypeformAiTaskCreatorSadEmotion}
            urlNeutralEmotion={urlTypeformAiTaskCreatorNeutralEmotion}
            urlHappyEmotion={urlTypeformAiTaskCreatorHappyEmotion}
          />
        )}
    </section>
  );
}

function getUrlInputProps(testType: TestType, url: string) {
  let placeholder;
  let fieldHelp;

  const urlWithHttp = addHttp(url);
  const isUrlValid = isValidURL(urlWithHttp);

  switch (testType) {
    case "app":
      placeholder = "https://play.google.com/store/apps/yourapp/";
      fieldHelp = (
        <>
          A URL to an app in the Google Play Store or Apple App Store{" "}
          {isUrlValid && (
            <a href={urlWithHttp} rel="noreferrer" target={"_blank"}>
              Open URL{" "}
              <span className={styles.openUrlIcon}>
                <FontAwesomeIcon icon={regular("external-link")} />
              </span>
            </a>
          )}
        </>
      );
      break;
    case "prototype":
      placeholder = "https://www.yourprototype.com";
      fieldHelp = (
        <>
          A URL to an online prototype (
          <a href={URL.figmaKnowledgeBase} target="_blank" rel="noreferrer">
            How to test with Figma
          </a>
          ,{" "}
          <a href={URL.adobeXdKnowledgeBase} target="_blank" rel="noreferrer">
            Adobe XD
          </a>
          ,{" "}
          <a href={URL.axureKnowledgeBase} target="_blank" rel="noreferrer">
            Axure
          </a>{" "}
          etc.).{" "}
          {isUrlValid && (
            <a href={urlWithHttp} rel="noreferrer" target={"_blank"}>
              Open URL{" "}
              <span className={styles.openUrlIcon}>
                <FontAwesomeIcon icon={regular("external-link")} />
              </span>
            </a>
          )}
        </>
      );
      break;
    default:
      placeholder = "https://www.yourwebsite.com/";
      fieldHelp = (
        <>
          The exact URL where testers start their test.{" "}
          {isUrlValid && (
            <a href={urlWithHttp} rel="noreferrer" target={"_blank"}>
              Open URL{" "}
              <span className={styles.openUrlIcon}>
                <FontAwesomeIcon icon={regular("external-link")} />
              </span>
            </a>
          )}
        </>
      );
  }

  return {
    placeholder,
    fieldHelp,
  };
}

function getIsUrlApp(url: string) {
  return URL.app.reduce((prev, curr) => {
    const regex = new RegExp(`.*${curr}.*`, "i");
    return prev ?? url.match(regex);
  }, false);
}
