import { useEffect, useRef } from "react";
import { AxiosError } from "axios";
import toLower from "lodash/toLower";

import { AiTaskCreatorFeedbackEmotion } from "./components/AiTaskCreator";
import {
  PaymentCard,
  PaymentCardBrand,
} from "./components/PaymentForm/paymentCard";
import {
  extractFieldFeedbackFromApiError,
  extractMessageFromApiError,
} from "./helpers";
import { ApiError } from "./reducers/types";
import { useAppSelector } from "./store";

export const urlMarketingWebsite = "https://www.userbrain.com/en";
export const urlUserTestingTemplates =
  "https://www.userbrain.com/en/user-testing-templates";
export const urlContact = "https://www.userbrain.com/en/contact";
export const urlTerms = "https://www.userbrain.com/en/terms";
export const urlPrivacy = "https://www.userbrain.com/en/privacy";
export const urlKnowledgeBase = "https://help.userbrain.net/help";
export const urlKnowledgeBaseHaventReceivedTrialSessions =
  "https://help.userbrain.com/help/why-haven-t-i-received-all-the-free-testers-in-my-trial";
export const urlTestSetupKnowledgeBase =
  "https://help.userbrain.com/help/how-do-i-set-up-a-test";
export const urlInsightsKnowledgeBase =
  "https://help.userbrain.com/help/how-to-use-automated-insights";
export const urlPricing = "https://www.userbrain.com/en/pricing";
const urlTypeformInsights = "https://zsggmirgs8y.typeform.com/to/HZ4vXWF9";
const urlTypeformAiTaskCreator = "https://zsggmirgs8y.typeform.com/to/mtl9lpr3";
export const urlBookADemo = "https://calendly.com/d/djj-9zf-nzh";

export function addUrlParams(url: string, params: { [key: string]: any }) {
  const urlObject = new URL(url);
  Object.keys(params).forEach((key) => {
    if (typeof params[key] === "undefined") return;
    urlObject.searchParams.append(key, params[key]);
  });
  return urlObject.toString();
}

export function addUtmParams(
  url: string,
  utmMedium: string,
  utmSource: string,
  utmCampaign?: string,
) {
  const utmParams = {
    utm_medium: utmMedium,
    utm_source: utmSource,
    utm_campaign: utmCampaign,
  };
  return addUrlParams(url, utmParams);
}

export function useUrlTypeformInsights(): string {
  const userEmail = useAppSelector((state) => state.user.user?.email);
  return `${urlTypeformInsights}#email=${userEmail ?? ""}`;
}

export function useUrlTypeformAiTaskCreator(
  emotion: AiTaskCreatorFeedbackEmotion,
  aiTestId: number,
): string {
  const userEmail = useAppSelector((state) => state.user.user?.email);
  return `${urlTypeformAiTaskCreator}#email=${
    userEmail ?? ""
  }&reaction=${emotion}&aitestid=${aiTestId}`;
}

export function HHMMSSToSeconds(hhmmss: string): number {
  const validFormat = /^(\d{1,2}:)?(\d{1,2}:)?\d{1,2}$/;
  if (!validFormat.test(hhmmss)) {
    return 0;
  }
  const [seconds, minutes, hours] = hhmmss.split(":").reverse().map(Number);
  return (hours || 0) * 3600 + (minutes || 0) * 60 + seconds;
}

export function toHHMMSS(
  input: any,
  options: {
    trimLeadingZero?: boolean;
  } = {},
): string {
  const sec_num = parseInt(input, 10); // don't forget the second param
  let hours: number | string = Math.floor(sec_num / 3600);
  let minutes: number | string = Math.floor((sec_num - hours * 3600) / 60);
  let seconds: number | string = sec_num - hours * 3600 - minutes * 60;
  let hasHours = false;

  if (hours > 0) {
    hasHours = true;
  }

  if (hours < 10) {
    hours = "0" + hours.toString();
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  let result;

  if (hasHours) {
    result = hours + ":" + minutes + ":" + seconds;
  } else {
    result = minutes + ":" + seconds;
  }

  if (options.trimLeadingZero) {
    result = result.replace(/^0+([0-9])/, "$1");
  }

  return result;
}

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

// TODO: removeGeneralFeedbackIfFieldFeedback should be removed at some point I think
export function createReduxApiError(
  error: AxiosError,
  removeGeneralFeedbackIfFieldFeedback: boolean = true,
): ApiError {
  return {
    message: extractMessageFromApiError(
      error,
      removeGeneralFeedbackIfFieldFeedback,
    ),
    fieldFeedback: extractFieldFeedbackFromApiError(error) as {
      [key: string]: string[];
    },
    status: error.response?.status,
    type: (error?.response?.data as { type?: string } | undefined)?.type,
  };
}

// Define a custom error class
export class ReduxApiErrorError extends Error {
  apiError: ApiError;
  constructor(apiError: ApiError) {
    super(apiError.message);
    this.apiError = apiError;
  }
}

export function paymentCardFromBilling(billing: any): PaymentCard | null {
  if (typeof billing?.card_last_four !== "undefined") {
    const expiryMonth = Number(billing.card_exp_month);
    const expiryYear = Number(billing.card_exp_year);

    const isExpired =
      new Date().getFullYear() > expiryYear ||
      (new Date().getFullYear() === expiryYear &&
        new Date().getMonth() + 1 > expiryMonth);

    return {
      lastFourDigits: billing.card_last_four,
      expiryMonth: expiryMonth,
      expiryYear: expiryYear,
      brand: toLower(billing.card_brand) as PaymentCardBrand,
      isExpired,
    };
  } else {
    return null;
  }
}

export function mergeRefs<T = any>(
  refs: Array<
    React.MutableRefObject<T> | React.LegacyRef<T> | undefined | null
  >,
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}
