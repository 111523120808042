import React, { Component, useEffect, useState } from "react";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import PropTypes from "prop-types";
import queryString from "query-string";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";

import {
  ButtonLink,
  ButtonPrimary,
  ErrorMessage,
  FormField,
  Heading1,
  InfoMessage,
  InputPassword,
  InputText,
  Stack,
  SuccessMessage,
} from "@/design-system";
import { usePrevious } from "@/helpers-ts";
import { LayoutCenterWithLogo } from "@/layouts/LayoutCenterWithLogo";
import { PASSWORD_RESET_CLEAR, PASSWORD_RESET_REQUEST } from "@/reducers/user";
import { FormFieldTermsAndConditions } from "../../components/FormFieldTermsAndConditions";
import { placeholderEmail, withRouter } from "../../helpers";

import { RegistrationForm } from "./RegistrationForm";

import styles from "./styles.module.css";

const cookies = new Cookies();

class Login extends Component {
  static propTypes = {
    form: PropTypes.oneOf([
      "login",
      "register",
      "reset-password",
      "invitation",
    ]),
  };

  static defaultProps = {
    form: "login",
  };

  constructor(props) {
    super(props);

    this.initialQueryString = queryString.parse(props.router.location.search);

    const email =
      this.initialQueryString.email ?? props.router.params.email ?? "";

    const plan = this.initialQueryString.plan ?? undefined;

    this.state = {
      loginMethod: "password",
      email,
      typeDelete: "",
      password: "",
      confirmEmailSuccess: props.router.location.state?.confirmEmailSuccess,
      plan,
    };

    this.refInputPassword = React.createRef();

    if (props.form === "invitation" && props.router.params.token) {
      props.logout();
      props.loadInvitation(props.router.params.token);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.user.signingIn !== this.props.user.signingIn &&
      this.props.user.signingIn === false
    ) {
      if (this.refInputPassword.current) {
        this.refInputPassword.current.focus();
        this.refInputPassword.current.setSelectionRange(
          0,
          this.state.password.length,
        );
      }
    }
  }

  componentDidMount() {
    if (this.initialQueryString.utm_source) {
      this.setTrafficSource(this.initialQueryString.utm_source);
    } else if (this.initialQueryString.gclid) {
      const isCookieConsentAccepted = cookies.get("CookieConsent");
      if (isCookieConsentAccepted === "true") {
        this.setTrafficSource(`adwords:${this.initialQueryString.gclid}`);
      } else {
        this.setTrafficSource("adwords:NOTRACKING");
      }
    }
  }

  setTrafficSource = (cvalue) => {
    const exdays = 365;
    const expires = new Date();
    expires.setTime(expires.getTime() + exdays * 24 * 60 * 60 * 1000);
    cookies.set("ub_src", cvalue, {
      path: "/",
      domain: process.env.REACT_APP_DEFAULT_COOKIE_DOMAIN,
      expires,
    });
  };

  onLoginFormSubmit = (e) => {
    e.preventDefault();
    const { onLogin } = this.props;
    const query = queryString.parse(this.props.router.location.search);
    onLogin(this.state.email, this.state.password, query.redirectTo);
  };

  onResetFormSubmit = (e) => {
    e.preventDefault();
    const { onReset } = this.props;
    onReset(this.state.email);
  };

  onPasswordResetOkButton = () => {
    this.props.router.navigate("/login");
    this.props.passwordResetClear();
  };

  onChangeEmail = (e) => {
    const email = e.target.value;
    this.setState({ email });
  };

  onChangePassword = (e) => {
    const password = e.target.value;
    this.setState({ password });
  };

  render() {
    const { loginMethod, email, password } = this.state;

    const {
      signingIn,
      signInError,
      passwordResetFetching,
      passwordResetError,
      passwordResetSuccessMessage,
      signOutSuccess,
      signOutReason,
    } = this.props.user;

    const signInErrorMessage = signInError?.message;
    const signInFieldFeedback = signInError?.fieldFeedback;

    if (this.props.form === "register") {
      return (
        <RegistrationForm
          email={email}
          password={password}
          onChangeEmail={this.onChangeEmail}
          onChangePassword={this.onChangePassword}
          plan={this.state.plan}
        />
      );
    }

    if (this.props.form === "invitation") {
      return <AcceptInvitation />;
    } else if (this.props.form === "login") {
      return (
        <LayoutCenterWithLogo>
          <div className={`${styles.loginBox} ${styles.form} card`}>
            <form onSubmit={this.onLoginFormSubmit}>
              <Stack>
                <Heading1 className={styles.formTitle}>Log In</Heading1>

                {this.props.user.passwordNewSuccessMessage && (
                  <SuccessMessage
                    icon={regular("check")}
                    message={this.props.user.passwordNewSuccessMessage}
                  />
                )}
                {signOutSuccess && signOutReason === "user" && (
                  <SuccessMessage
                    icon={regular("check")}
                    message={"Logged out successfully"}
                  />
                )}
                {signOutSuccess && signOutReason === "error" && (
                  <InfoMessage
                    icon={regular("info-circle")}
                    message={"Your session has expired"}
                  />
                )}
                {this.state.confirmEmailSuccess === true && (
                  <SuccessMessage
                    icon={regular("check")}
                    message={"Success! Your email has been verified."}
                  />
                )}
                {signInErrorMessage && (
                  <ErrorMessage
                    icon={regular("ban")}
                    message={signInErrorMessage}
                  />
                )}

                <FormField
                  label={"Email Address"}
                  errorMessage={signInFieldFeedback?.email}
                >
                  <InputText
                    data-testid="login-input-email"
                    type={"email"}
                    autoComplete={"username email"}
                    placeholder={placeholderEmail}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    value={email}
                    fullWidth
                  />
                  {loginMethod === "email" && (
                    <div className={styles["forgot-password"]}>
                      <ButtonLink
                        onClick={() =>
                          this.setState({ loginMethod: "password" })
                        }
                      >
                        Enter a password
                      </ButtonLink>
                    </div>
                  )}
                </FormField>

                {loginMethod === "password" && (
                  <FormField
                    label={"Password"}
                    errorMessage={signInFieldFeedback?.password}
                  >
                    <InputPassword
                      data-testid="login-input-password"
                      autoComplete={"current-password"}
                      placeholder={"Enter your password"}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                      innerRef={this.refInputPassword}
                      value={password}
                      fullWidth
                    />
                    <div className={styles["forgot-password"]}>
                      <Link to={"/reset-password"}>Forgot Password?</Link>
                    </div>
                  </FormField>
                )}

                <ButtonPrimary
                  type={"submit"}
                  data-testid="login-button-submit"
                  fullWidth
                  className={`${styles.buttonPromo} ${styles.buttonLogin}`}
                  disabled={signingIn}
                >
                  {loginMethod === "password"
                    ? signingIn
                      ? "Logging in…"
                      : "Log In"
                    : "Get a Magic Link"}
                </ButtonPrimary>

                <div className={styles.belowButtonText}>
                  Your first time?{" "}
                  <Link to={"/register"}>Create an account</Link>
                </div>
              </Stack>
            </form>
          </div>
        </LayoutCenterWithLogo>
      );
    } else if (this.props.form === "reset-password") {
      return (
        <LayoutCenterWithLogo>
          {!passwordResetFetching && passwordResetSuccessMessage ? (
            <div className={`${styles.loginBox} ${styles.form} card`}>
              <p>{passwordResetSuccessMessage}</p>
              <ButtonPrimary
                type={"submit"}
                onClick={this.onPasswordResetOkButton}
                fullWidth
                className={styles.buttonPromo}
              >
                Got it
              </ButtonPrimary>
            </div>
          ) : (
            <div className={`${styles.loginBox} ${styles.form} card`}>
              <form onSubmit={this.onResetFormSubmit}>
                <Stack>
                  <Heading1 className={styles.formTitle}>
                    Reset Password
                  </Heading1>
                  {passwordResetError?.message && (
                    <ErrorMessage
                      icon={regular("ban")}
                      message={passwordResetError.message}
                    />
                  )}

                  <FormField
                    label={"Email Address"}
                    errorMessage={passwordResetError?.fieldFeedback?.email}
                  >
                    <InputText
                      type={"email"}
                      placeholder={placeholderEmail}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      value={email}
                      fullWidth
                    />
                  </FormField>
                  <ButtonPrimary
                    type={"submit"}
                    fullWidth
                    disabled={passwordResetFetching}
                    className={`${styles.buttonPromo} ${styles.buttonResetPassword}`}
                  >
                    {passwordResetFetching ? "Resetting…" : "Reset Password"}
                  </ButtonPrimary>
                  <div className={styles.belowButtonText}>
                    You didn’t forget your password?{" "}
                    <Link to={"/login"}>Return to login</Link>
                  </div>
                </Stack>
              </form>
            </div>
          )}
        </LayoutCenterWithLogo>
      );
    }
  }
}

function AcceptInvitation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [typeDelete, setTypeDelete] = useState("");
  const [password, setPassword] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const { token } = useParams();

  const invitationFetching = useSelector((state) => state.invitation.fetching);
  const invitation = useSelector((state) => state.invitation.invitation);
  const invitationError = useSelector((state) => state.invitation.error);

  const signUpError = useSelector((state) => state.user.signUpError);
  const signUpIsError = useSelector((state) => state.user.signUpError);
  const signingUp = useSelector((state) => state.user.signingUp);
  const prevSigningUp = usePrevious(signingUp);
  const signUpErrorMessage = signUpError?.message;
  const signUpFieldFeedback = signUpError?.fieldFeedback;

  function handleClickCreateAccount() {
    dispatch({
      type: "SIGN_UP",
      email: invitation?.email,
      password,
      isTermsAccepted,
      acceptInvitation: true,
      acceptInvitationToken: token,
    });
  }

  useEffect(() => {
    if (signingUp === false && prevSigningUp === true && !signUpIsError) {
      navigate("/");
    }
  }, [signingUp, prevSigningUp, signUpIsError, navigate]);

  return (
    <LayoutCenterWithLogo>
      <div className={`${styles.form} card ${styles.invitationForm}`}>
        <Stack>
          <Heading1 className={styles.formTitle}>Accept invitation</Heading1>
          {signUpErrorMessage && (
            <ErrorMessage icon={regular("ban")} message={signUpErrorMessage} />
          )}
          {invitationError && (
            <ErrorMessage message={invitationError.message} />
          )}
          <p className={styles.inviteSubline}>
            {invitation ? invitation.owner : "Someone"} has invited you to join
            their Userbrain account.
          </p>
          {invitation?.existing && (
            <div className={styles.deleteAccountWarning}>
              <Stack>
                <div>
                  ⚠️ Accepting this invitation will delete all data in your
                  current account ({invitation.email}). Is this what you want to
                  do? (can’t be undone)
                </div>
                <InputText
                  placeholder={"Type DELETE to confirm"}
                  value={typeDelete}
                  onChange={(e) => setTypeDelete(e.target.value)}
                  fullWidth
                />
              </Stack>
            </div>
          )}

          <FormField errorMessage={signUpFieldFeedback?.email}>
            <InputText
              type={"email"}
              autoComplete={"email"}
              placeholder={"Email"}
              value={
                invitation
                  ? invitation.email
                  : invitationFetching
                  ? "Loading invitation…"
                  : ""
              }
              readOnly
              fullWidth
            />
          </FormField>

          <FormField errorMessage={signUpFieldFeedback?.password}>
            <InputPassword
              autoComplete={"new-password"}
              placeholder={"Password"}
              onChange={(e) => setPassword(e.target.value)}
              withShowPasswordToggle={true}
              value={password}
              autoFocus
              fullWidth
            />
          </FormField>

          <FormFieldTermsAndConditions
            errorMessage={signUpFieldFeedback?.terms_accepted}
            onChange={(e) => setIsTermsAccepted(e.target.checked)}
            checked={isTermsAccepted}
          />

          {invitation?.existing ? (
            <>
              <ButtonPrimary
                onClick={handleClickCreateAccount}
                fullWidth
                className={styles.buttonPromo}
                disabled={
                  signingUp ||
                  !invitation?.email ||
                  (invitation?.existing && typeDelete !== "DELETE")
                }
              >
                {signingUp ? "Accepting invitation…" : "Accept invitation"}
              </ButtonPrimary>
            </>
          ) : (
            <>
              <ButtonPrimary
                onClick={handleClickCreateAccount}
                fullWidth
                className={styles.buttonPromo}
                disabled={signingUp || !invitation?.email}
              >
                {signingUp ? "Creating account…" : "Create account"}
              </ButtonPrimary>
            </>
          )}
        </Stack>
      </div>
    </LayoutCenterWithLogo>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    invitationFetching: state.invitation.fetching,
    invitation: state.invitation.invitation,
    invitationError: state.invitation.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: "SIGN_OUT" }),
    onLogin: (username, password, redirectTo) =>
      dispatch({
        type: "SIGN_IN",
        credentials: { username, password },
        redirectTo,
      }),
    onReset: (email) => dispatch({ type: PASSWORD_RESET_REQUEST, email }),
    loadInvitation: (token) => dispatch({ type: "INVITATION_REQUEST", token }),
    passwordResetClear: () => dispatch({ type: PASSWORD_RESET_CLEAR }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
