import React from "react";
import { cn } from "@sys42/utils";

import { formatDateWithoutTime, pluralizer } from "../../helpers";
import { UsageBoxItem } from "../UsageBoxItem";

// import { } from '../../system42'
import styles from "./styles.module.css";

export function UsageBoxBilling(props) {
  const {
    className,
    title,
    currentPeriodStart,
    currentPeriodEnd,
    billingCycle,
    creditsAvailable,
    testersAvailable,
    testersUsedThisMonth,
    testersIncluded,
    testersRollOverLimit,
    freeTrial,
    hasSubscription,
    planName,
    isCancelled,
    ...containerProps
  } = props;

  let tooltipContentTesters = null;

  if (isCancelled) {
    tooltipContentTesters = (
      <>
        Your remaining testers can be used until{" "}
        {formatDateWithoutTime(currentPeriodEnd)}
      </>
    );
  } else if (testersAvailable === 0 && hasSubscription) {
    tooltipContentTesters = (
      <>
        You’ll receive {testersIncluded} new{" "}
        {pluralizer("tester")(testersIncluded)} on{" "}
        {formatDateWithoutTime(currentPeriodEnd)}. If you need more testers
        immediately, simply update your subscription to select a larger plan or
        purchase credits.
      </>
    );
  } else if (hasSubscription) {
    tooltipContentTesters = (
      <>
        You’ll receive {testersIncluded} new{" "}
        {pluralizer("tester")(testersIncluded)} on{" "}
        {formatDateWithoutTime(currentPeriodEnd)}. All unused testers roll over
        until you cancel your subscription.
      </>
    );
  } else {
    tooltipContentTesters = (
      <>
        If you need more testers, simply upgrade to a paid plan or purchase
        credits.
      </>
    );
  }

  return (
    <section
      className={cn(className, styles.usageBox, "card")}
      {...containerProps}
    >
      <div className={styles.header}>
        <h1>{title}</h1>
        {freeTrial?.isActive && !hasSubscription && (
          <div className={styles.currentPeriod}>
            14 day free trial ({freeTrial.daysLeft} days left)
          </div>
        )}
      </div>
      <div className={styles.usageItems}>
        {creditsAvailable !== null && (
          <UsageBoxItem
            className={styles.usageBoxItem}
            title={"Credits:"}
            amountAvailable={creditsAvailable}
            subject={"credit"}
            tooltipContent={
              <>
                Use credits to get Userbrain testers. 1 credit = 1 Userbrain
                tester. Credits never expire.
              </>
            }
          />
        )}
        {testersIncluded > 0 && (
          <UsageBoxItem
            className={styles.usageBoxItem}
            title={`${planName} Plan:`}
            amountAvailable={testersAvailable}
            meterTitle={"Testers available"}
            subject={"tester"}
            tooltipContent={tooltipContentTesters}
          />
        )}
      </div>
    </section>
  );
}
